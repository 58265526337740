@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600&display=swap');
body {
  font-family: 'Poppins';
}

.dashboard-chart {
  > button {
    background-color: transparent;
    border: none;
    outline: none;
    padding: 0 10px;
    border-radius: 5px;

    &:hover {
      background-color: #3252e7;
    }
  }
}

.totalOrders {
  display: flex;
  align-items: center;
  gap: 10px;
}
